/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { LastLocationProvider } from "react-router-last-location";
import { Routes } from "./app/router/Routes";
import { LayoutSplashScreen, ThemeProvider } from "./_metronic";
import { getCurrentPushSubscription, registerPushNotifications, registerServiceWorker } from './app/utils/PushUtils';
import { checkIfPushIsRegistered } from "./app/crud/user.crud";
import Swal from "sweetalert2";

export default function App({ store, persistor, basename }) {
	useEffect(() => {
		async function setUpServiceWorker() {
			try {
				await registerServiceWorker();
			} catch (e) {
				console.log(e);
			}
		}

		function isMobile() {
			return /Mobi/.test(navigator.userAgent);
		};

		setUpServiceWorker();

		async function monitorNotificationPermission() {
			if (isMobile()) {
				console.log('Verificando status de permissão de notificações.')

				const subscription = await getCurrentPushSubscription();

				if (subscription) {
					checkIfPushIsRegistered({ endpoint: subscription.endpoint }).catch(err => {
						console.error('Houve um erro ao buscar a subscripion.')
					}).then(async res => {
						if (res?.status == 200) {
							if (res?.data?.hasSubscription) {
								console.log('Subscription encontrada!')
							} else {
								showNotificationPermissionDialog();
							}
						}
					});
				} else if (subscription == null && Notification.permission == 'granted') {
					showNotificationPermissionDialog();
				}
			}
		}

		monitorNotificationPermission();
		setInterval(monitorNotificationPermission, 1000 * 60 * 60);

		async function showNotificationPermissionDialog() {
			await Swal.fire({
				title: 'Atenção!',
				text: 'As notificações foram desativadas no CashBarber. Deseja reativá-las?',
				icon: 'info',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: "Não",
				confirmButtonText: 'Sim'
			}).then((result) => {
				if (result.isConfirmed) {
					registerPushNotifications();
				}
			});
		}
	}, []);

	return (
		/* Provide Redux store */
		<Provider store={store}>
			{/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
			<PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
				{/* Add high level `Suspense` in case if was not handled inside the React tree. */}
				<React.Suspense fallback={<LayoutSplashScreen />}>
					{/* Override `basename` (e.g: `homepage` in `package.json`) */}
					<BrowserRouter basename={basename}>
						{/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
						<LastLocationProvider>
							{/* Provide Metronic theme overrides. */}
							<ThemeProvider>
								{/* Provide `react-intl` context synchronized with Redux state.  */}
								<Routes />
							</ThemeProvider>
						</LastLocationProvider>
					</BrowserRouter>
				</React.Suspense>
			</PersistGate>
		</Provider>
	);
}
